import { loadStorage } from 'src/actionCreators/storageActionCreators';
import { StorageService } from 'src/services/Storage';
import { getProject } from './actionCreators/projectActionCreators';
import store from 'src/store';

let storageInstance = undefined;
export const initStorage = (id) => {
  storageInstance = new StorageService(
    `im_data_${process.env.NODE_ENV}_org_${id}`
  );
  store.dispatch(loadStorage(storageInstance?.getItem()));
};
export const saveStorage = (data) => {
  storageInstance?.setItem(storageInstance?.name, {
    last_projects: data.storage.last_projects,
    warnings_ignored: data.storage.warnings_ignored,
  });
};

export const userCurrentOrgId = () =>
  store.getState()?.user?.data?.current_organization_id || undefined;
export const userMemberships = () =>
  store.getState()?.user?.data?.memberships_count || 1;
export const isStaff = () =>
  Boolean(store.getState()?.user?.data?.flags?.includes('staff'));

export const surveyOnly = () =>
  Boolean(
    store.getState()?.organizationCurrent?.data?.flags?.includes('survey-only')
  );
export const isFullSuite = () =>
  Boolean(
    store.getState()?.organizationCurrent?.data?.flags?.includes('full-suite')
  );
export const isSubscribed = () =>
  !store.getState()?.organizationCurrent?.data?.flags?.includes('unlicensed');

export const hasTrintApiKey = () =>
  Boolean(store.getState()?.organizationCurrent?.data?.trint_api_key);

export const hasStories = () =>
  Boolean(
    store
      .getState()
      ?.organizationCurrent?.data?.flags?.includes('stories-enabled')
  );
export const reloadProject = (projectId) =>
  store.dispatch(getProject(projectId));

const check_permission = (permission) =>
  store.getState()?.user?.data?.permissions?.[userCurrentOrgId()]
    ? store
        .getState()
        ?.user.data.permissions[userCurrentOrgId()].includes(permission)
    : true; /* temporarily if user has zero permissions for this company, allow true*/

export const canAiTagging = () =>
  Boolean(
    store
      .getState()
      ?.organizationCurrent?.data?.flags?.includes('ai-tagging') || true
  );
export const canAiTranslate = () =>
  Boolean(
    store
      .getState()
      ?.organizationCurrent?.data?.flags?.includes('ai-translation') || true
  );
export const canAiWriter = () =>
  Boolean(
    store.getState()?.organizationCurrent?.data?.flags?.includes('ai-writer') ||
      true
  );
export const canCreateTag = () => check_permission('Can_Manage_Tags');
export const canManageTag = () => check_permission('Can_Manage_Tags');
export const canManageChart = () =>
  surveyOnly() ? false : check_permission('Can_Manage_Charts');
export const canManageContact = () =>
  surveyOnly() ? false : check_permission('Can_Manage_Contacts');
export const canManageMember = () =>
  surveyOnly() ? false : check_permission('Can_Manage_Members');
export const canManageReport = () =>
  surveyOnly() ? false : check_permission('Can_Manage_Reports');
export const canManageGrants = () =>
  surveyOnly() ? false : check_permission('Can_Manage_Grants');
export const canManageProject = () => check_permission('Can_Manage_Projects');
export const canManageSurvey = () => check_permission('Can_Manage_Surveys');
export const canManageAttachment = () =>
  check_permission('Can_Manage_Attachments');
export const canManageOrganization = () =>
  check_permission('Can_Manage_Organizations');
export const canBuildSurvey = () => check_permission('Can_Manage_Surveys');

export const canImportFromTrint = () =>
  check_permission('Can_Import_From_Trint');

export const LICENSE_UPGRADE_URL =
  'https://share.hsforms.com/1qra_5DHJSjGnJoLSpTUwPw2adk9';

const PLANS = [
  'Individual-Starter',
  'Individual-Evaluator',
  'Individual-NonProfit',
  'Corporate-Small',
  'Corporate-Medium',
  'Corporate-Large',
];

export const PERMISSIONS = [
  'Can_Manage_Tags',
  'Can_Manage_Charts',
  'Can_Manage_Contacts',
  'Can_Manage_Members',
  'Can_Manage_Reports',
  'Can_Manage_Projects',
  'Can_Manage_Surveys',
  'Can_Manage_Grants',
  'Can_Manage_Attachments',
  'Can_Manage_Organizations',
  'Can_Import_From_Trint',
];

export const retrieveTagsFromStorage = () => {
  const survey_taggings =
    store.getState()?.surveyJsonapi.data?.survey_question_tags || [];
  const recent_taggings = store.getState()?.projectTagsRecent.data || [];
  const manual_taggings =
    Object.values(store.getState().cachedSurveyAnswers || {})
      .flatMap((a) =>
        a?.data?.flatMap((tag) => tag?.manual_taggings?.flatMap((m) => m?.tag))
      )
      .filter(Boolean) || [];
  const mapWithGroup = (array) =>
    array?.map((t) => ({ ...t, tagGroup: t?.tag_categories?.[0] ?? {} })) || [];
  return [
    mapWithGroup(survey_taggings),
    mapWithGroup(recent_taggings),
    mapWithGroup(manual_taggings),
  ];
};

export const getTagFromStorage = (tagId) =>
  retrieveTagsFromStorage().find((t) => t.id == tagId);

export const getTagsWithTagGroups = () => {
  const survey_taggings =
    store.getState()?.surveyJsonapi.data?.survey_question_tags || [];
  const recent_taggings = store.getState()?.projectTagsRecent.data || [];
  const mapWithGroup = (array) =>
    array?.map((t) => ({ ...t, tagGroup: t?.tag_categories?.[0] ?? {} })) || [];
  return mapWithGroup(survey_taggings).concat(mapWithGroup(recent_taggings));
};
