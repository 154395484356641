import reducerRegistry from 'im/api/registry';
import ResourceCollection from 'im/api/ResourceCollection';

const apiConfig = {
  root: '/v3/ai/chart_insights',
  type: 'ai_chart_insights',
  api: {
    allow: ['create'],
  },
};

export const AIChartInsights = new ResourceCollection(
  apiConfig,
  'aiChartInsightsJsonapi'
);
reducerRegistry.register(AIChartInsights);

export default AIChartInsights.api;
