import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Quill from 'quill';
import React from 'react';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { showNotification } from 'src/actionCreators/notificationsActionCreators';
import {
  getReport,
  updateReport,
} from 'src/actionCreators/reportActionCreators';
import taggingsApi from 'src/api/Taggings';
import FlexColumn from 'src/components/FlexColumn/FlexColumn';
import {
  Card,
  Container,
  Section,
  Progress,
  CardFooter,
} from 'src/components/IMUI';
import Page from 'src/components/Page/Page';
import {
  getAutoSuggestedTaggingDetails,
  isNumeric,
  getNumberFromText,
} from 'src/components/TaggingType/taggingTypeHelper';
import reportStatus from 'src/data/reportStatus';
import history from 'src/historyInstance';
import { tagsToHighlights } from 'src/utils/tagsToHighlights';

import { where } from 'im/api/Query';
import aiTaggingResponsesApi from 'src/api/AITaggingResponses';
import {
  applyColoringStrategy,
  COLORING_STRATEGIES,
} from 'src/pages/App/Analysis/TagEditor/components/coloringStrategy';
import EditorMinimap from 'src/pages/App/Analysis/TagEditor/components/EditorMinimap';
import QuotesList from 'src/pages/App/Analysis/TagEditor/components/Quotes';
import TagEditorSidebar from 'src/pages/App/Analysis/TagEditor/components/TagEditorSidebar';
import TaggingDetailsSelector from 'src/pages/App/Analysis/TagEditor/components/TaggingDetailsSelector';
import TaggingInContext from 'src/pages/App/Analysis/TagEditor/components/TaggingInContext';
import TaggingSuggestionInContext from 'src/pages/App/Analysis/TagEditor/components/TaggingSuggestionInContext';
import AITaggingSuggestionEditor from 'src/pages/App/Analysis/TagEditor/components/AITaggingSuggestionEditor';
import Waiting from 'src/pages/App/Analysis/TagEditor/components/Waiting';
import cls from 'src/pages/App/Analysis/TagEditor/TagEditor.module.css';
import FormatEditor from 'src/pages/App/Analysis/TagEditor/components/FormatEditor';
import StorageService from 'src/services/Storage';
const cx = classNames.bind(cls);
const BlockBlot = Quill.import('blots/block');
BlockBlot.tagName = 'div';
Quill.register(BlockBlot, true);

const AI_TAGGING_SUGGESTION_STATUS_APPROVE = 'approve';
const AI_TAGGING_SUGGESTION_STATUS_DECLINE = 'decline';

const Inline = Quill.import('blots/inline');
const AI_TAGGING_BLOT_NAME = 'ai-tagging';
class AITaggingBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.classList.add(cx(cls.aiTaggingContainer));
    if (cx(cls[value])) node.classList.add(cx(cls[value]));

    return node;
  }

  static formats(node) {
    return node.classList.contains(cx(cls.aiTaggingContainer));
  }
}

AITaggingBlot.blotName = AI_TAGGING_BLOT_NAME;
AITaggingBlot.tagName = 'span';
Quill.register(AITaggingBlot);

const taggingAlreadyExists = (newT, taggings) =>
  taggings.some(
    (curr) =>
      curr.tag?.id == newT.tag?.id &&
      curr.report_content_end === newT.end &&
      curr.report_content_start === newT.start
  );
const didReportChange = (props, nextProps) =>
  (nextProps.report.reportUid !== props.report.reportUid ||
    nextProps.project.uid !== props.project.uid) &&
  nextProps.report.reportUid &&
  nextProps.project.uid;
const didContentChange = (props, nextProps) =>
  props.report.content_html !== nextProps.report.content_html;
const didReportLoad = (state, nextProps) =>
  state.hasTaggings &&
  !window.quill?.container?.textContent &&
  nextProps.report.content_html?.length > 0;

@connect(
  (state) => ({
    report: state.report,
    taggings: state.taggings,
    taggingSuggestions: state.aiTaggingResponses,
    project: state.project,
    organizationCurrent: state.organizationCurrent,
    user: state.user,
  }),
  {
    createTagging: taggingsApi.create,
    updateTagging: taggingsApi.put,
    deleteTagging: taggingsApi.destroy,
    getTaggings: taggingsApi.findAllPerTaggable,
    getTaggingSuggestions: aiTaggingResponsesApi.findAll,
    updateTaggingSuggestion: aiTaggingResponsesApi.put,
    getReport,
    updateReport,
    showNotification,
  }
)
export default class TagEditor extends React.PureComponent {
  static propTypes = {
    location: ReactRouterPropTypes.location,
    match: ReactRouterPropTypes.match,

    report: PropTypes.object,
    taggings: PropTypes.object,
    taggingSuggestions: PropTypes.object,
    organizationCurrent: PropTypes.object,
    user: PropTypes.object,
    project: PropTypes.object,

    createTagging: PropTypes.func.isRequired,
    updateTagging: PropTypes.func.isRequired,
    deleteTagging: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    getTaggings: PropTypes.func.isRequired,
    getTaggingSuggestions: PropTypes.func.isRequired,
    updateTaggingSuggestion: PropTypes.func.isRequired,
    updateReport: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  locationRestored = false;
  state = {
    coloringStrategy: COLORING_STRATEGIES.SINGLE_COLOR_SHOW_OVERLAPS_SHOW_HOVER,
    showTaggingSuggestions: false,
    selectionStart: 0,
    selectedText: null,
    selectionEnd: 0,
    tagsInContext: [],
    taggingSuggestionsInContext: [],
    hasTaggings: false,
    editTaggingSuggestion: null,
  };

  componentDidMount() {
    if (StorageService.getItem('--highlight-color'))
      document?.documentElement?.style?.setProperty(
        '--highlight-color',
        StorageService.getItem('--highlight-color')
      );
    this.getReport();
  }
  componentDidUpdate(oldProps) {
    if (!this.props.report.pending && didReportChange(oldProps, this.props)) {
      this.doRequestTaggings(true);
      this.doRequestTaggingSuggestions(true);
    }
    if (!this.props.report.pending && didContentChange(oldProps, this.props)) {
      this.doRequestTaggings(true);
      this.doRequestTaggingSuggestions(true);
    }

    if (
      !this.props.report.pending &&
      (this.props.taggings.data !== oldProps.taggings.data ||
        this.props.taggingSuggestions.data !== oldProps.taggingSuggestions.data)
    ) {
      this.refreshUi();
      this.forceUpdate();
    }

    if (didReportLoad(this.state, this.props))
      this.resetDocument(this.props.report.content_html, true);
  }
  restoreLocation() {
    if (!this.props.location.search?.length) return;
    if (this.locationRestored) return;
    this.locationRestored = true;
    this.setSelection({
      startPosition: queryString.parse(this.props.location.search)?.start
        ? parseInt(queryString.parse(this.props.location.search).start, 10)
        : undefined,
      endPosition: queryString.parse(this.props.location.search)?.end
        ? parseInt(queryString.parse(this.props.location.search).end, 10)
        : undefined,
    });
  }
  getReport() {
    this.props
      .getReport(
        this.props.match.params.projectId,
        this.props.match.params.reportId
      )
      .then((res) => {
        const isParsed = res?.data?.status == reportStatus.PARSED;
        if (isParsed) this.initQuill();
        if (isParsed) this.doRequestTaggings(true);
        if (isParsed && this.state.showTaggingSuggestions)
          this.doRequestTaggings(true);
      });
  }
  doRequestTaggings = (isInit) => {
    if (!this.props.project.uid || !this.props.report.reportUid) return;
    this.props
      .getTaggings(
        where({ taggable_id: this.props.report.reportUid })
          .include('tag', 'tag.tag_categories')
          .filter('project_id_eq', this.props.project.uid)
          .filter('scope', 'content')
          .paginate({ size: 3000 })
          .pending(isInit ? 'init' : '')
      )
      .then(() => {
        if (isInit) this.setState({ hasTaggings: true });
        if (isInit) this.bindQuillEvents();
        if (isInit) this.restoreLocation();
      });
  };

  doRequestTaggingSuggestions = (isInit) => {
    if (!this.props.project.uid || !this.props.report.reportUid) return;
    this.props
      .getTaggingSuggestions(
        where({ taggable_id: this.props.report.reportUid })
          .include('tag', 'report', 'tag.tag_categories')
          .filter('tagging_request_project_report_id_eq', this.props.report.id)
          .paginate({ size: 1000 })
          .pending(isInit ? 'init' : '')
      )
      .then(() => {
        this.refreshQuillMarks();
      });
  };

  updateHighlight(range) {
    if (this._range?.length)
      this.quill?.removeFormat(this._range?.index, this._range?.length);
    const currRange = range ?? this._range;
    if (currRange?.length > 0)
      this.quill?.formatText(currRange?.index, currRange?.length, {
        background: StorageService.getItem('--highlight-color') ?? '#c1fa8b',
      });
    this._range = currRange;
  }

  handleSelectionChange = (_range) => {
    this.updateHighlight(_range);

    const range = _range || {
      index: this.state.selectionStart || 0,
      length: this.state.selectionEnd - (this.state.selectionStart || 0),
    };
    const hasHighlight = range.length > 0;
    const selectionStart = hasHighlight ? range.index : 0;
    const selectionEnd = hasHighlight ? range.index + range.length : 0;
    const selectedText = hasHighlight
      ? this.quill.getText(selectionStart, range.length)
      : null;
    const tagsInContext = hasHighlight
      ? this.props.taggings?.data.filter(
          (el) =>
            selectionStart === el.report_content_start &&
            selectionEnd === el.report_content_end
        )
      : this.props.taggings?.data.filter(
          (el) =>
            range.index >= el.report_content_start &&
            range.index <= el.report_content_end
        );

    const taggingSuggestionsInContext = this.state.showTaggingSuggestions
      ? hasHighlight
        ? this.props.taggingSuggestions?.data.filter((el) => {
            const { startIndex, endIndex } = this.getQuillIndicesFor(
              el.content
            );

            return selectionStart === startIndex && selectionEnd === endIndex;
          })
        : this.props.taggingSuggestions?.data.filter((el) => {
            const { startIndex, endIndex } = this.getQuillIndicesFor(
              el.content
            );

            return range.index >= startIndex && range.index <= endIndex;
          })
      : [];

    this.setState({
      selectionIndex: selectionStart,
      selectionStart,
      selectionEnd,
      selectedText,
      tagsInContext,
      taggingSuggestionsInContext,
    });

    if (!hasHighlight) {
      this.applyHighlights(
        this.props.taggings.data,
        tagsInContext.map((i) => i.id) ?? []
      );

      this.applyTaggingSuggestionHighlights(
        this.props.taggingSuggestions.data,
        this.state.taggingSuggestionsInContext.map((i) => i.id) ?? []
      );
    }
  };

  setSelection = ({
    startPosition,
    endPosition,
    _cursor = undefined,
    scrollToSelection = false,
  }) => {
    const a = Number(startPosition || 0);
    const z = Number(endPosition || 0);
    const canFocus = z - a > 0;
    const allSame =
      this.state.endPosition == z && this.state.selectionStart == a;

    if (!allSame) {
      this.setState({ selectionStart: a, selectionEnd: z }, () => {
        this.quill?.setSelection(startPosition, endPosition - startPosition);
        this.applyHighlights(
          this.props.taggings.data,
          this.state.tagsInContext?.map((i) => i.id) ?? []
        );
      });
    }

    window.setTimeout(() => {
      this.quill?.setSelection(startPosition, endPosition - startPosition);
      canFocus ? scrollToSelection && this.quill?.focus() : this.quill?.blur();
    });
  };
  createSingleTagging = async (tagging) => {
    if (!tagging?.tag) {
      this.props.showNotification({
        title: 'Adding taggings',
        message: 'No tags were selected to tag the content with',
        level: 'warning',
        autoDismiss: 10,
      });
      return Promise.reject();
    }
    if (taggingAlreadyExists(tagging, this.props.taggings.data)) {
      this.props.showNotification({
        title: 'Adding taggings',
        message:
          "You can't apply the same tag to the same part of the content twice",
        level: 'warning',
      });
      this.setSelection({
        startPosition: tagging?.report_content_start,
        endPosition: tagging?.report_content_end,
        cursor: 0,
        scrollToSelection: true,
      });
      return Promise.reject();
    }

    const taggingContent = this.quill?.getText(
      tagging.start || 0,
      tagging.end - tagging.start
    );

    const baseAttributes = {
      report_content_start: tagging.start,
      report_content_end: tagging.end,
      content: taggingContent,
      type: 'taggings',
    };

    const attributes = isNumeric(taggingContent)
      ? {
          ...baseAttributes,
          ...getNumberFromText(taggingContent),
        }
      : baseAttributes;

    return this.props.createTagging(
      where().payload({
        attributes: attributes,
        relationships: [
          { relName: 'tag', type: 'tags', id: tagging.tag?.id },
          {
            relName: 'taggable',
            type: 'reports',
            id: this.props.report.reportUid,
          },
          { relName: 'user', type: 'users', id: this.props.user.data.id },
          {
            relName: 'organization',
            type: 'organizations',
            id: this.props.organizationCurrent.data.id,
          },
          {
            relName: 'project',
            type: 'projects',
            id: this.props.project.uid,
          },
        ],
      })
    );
  };

  addTaggings = async (_newTaggings) => {
    const newTaggings = (
      Array.isArray(_newTaggings) ? _newTaggings : [_newTaggings]
    ).filter(Boolean);
    if (!newTaggings?.length) return;

    try {
      const createdTaggings = await Promise.all(
        newTaggings.map(this.createSingleTagging)
      );
      if (!createdTaggings?.length) return;
      setTimeout(() => this.refreshUi());

      const firstTagging = createdTaggings[0];
      const autoSuggestion = getAutoSuggestedTaggingDetails(
        firstTagging.data.content
      );
      if (autoSuggestion) {
        this.onTaggingEdit({ ...firstTagging.data, ...autoSuggestion });
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleEditTagging = (tagging, taggingDetails) => {
    this.props.updateTagging(
      where({ id: tagging.id }).payload({
        attributes: {
          quantity: null,
          amount: null,
          currency: null,
          year: null,
          month: null,
          day: null,
          location: null,
          ...taggingDetails,
        },
        relationships: [
          {
            relName: 'taggable',
            type: 'reports',
            id: this.props.report.reportUid,
          },
          { relName: 'project', type: 'projects', id: this.props.project.uid },
        ],
      })
    );
    this.onDetailsClose();
  };

  refreshUi = () => {
    if (!this.quill?.container?.textContent) return;
    this.handleSelectionChange();

    if (this.state.showTaggingSuggestions) {
      this.applyTaggingSuggestionHighlights(this.props.taggingSuggestions.data);

      if (this.state.taggingSuggestionsInContext.length == 1) {
        this.removeTaggingSuggestionHighlights(
          this.state.taggingSuggestionsInContext
        );
      }
    } else {
      this.removeTaggingSuggestionHighlights(
        this.props.taggingSuggestions.data
      );
    }

    if (this.state.tagsInContext.length == 1) {
      this.removeHighLights(
        this.props.taggings.data.filter(
          (ot) => !this.props.taggings.data.map(({ id }) => id).includes(ot.id)
        )
      );
    }
    this.applyHighlights(
      this.props.taggings.data.filter(
        (t) => !this.props.taggings.data.map(({ id }) => id).includes(t.id)
      )
    );

    this.refreshQuillMarks();
  };

  resetDocument = (content, initial) => {
    const contentHtml =
      this.props.report.content_html ||
      'GENERATED REPORT CONTENT NOT AVAILABLE';
    const scrollPos = initial ? 0 : this.quill?.scrollingContainer.scrollTop;

    if (this.quill?.root?.parentElement) {
      const parent = this.quill.root.parentElement;
      parent.removeChild(this.quill.root);

      const mappedDeltas = this.quill.clipboard.convert(
        contentHtml.replaceAll('<p><hr></p>', '\r\n').replaceAll('\n\n\n', '\n')
      );
      this.quill.setContents(mappedDeltas);

      parent.appendChild(this.quill.root);
      setTimeout(() => {
        this.applyHighlights(this.props.taggings.data);
        this.bindQuillEvents();
        this.refreshQuillMarks();
        if (scrollPos) {
          this.handleScrollTo(scrollPos, true);
        }
      }, 1);
    }
  };

  initQuill = () => {
    this.quill = new Quill(`.${cls.editor}`, {
      formats: [
        'bold',
        'background',
        'ai-tagging',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'direction',
      ],
      modules: { toolbar: false, history: { userOnly: true } },
      readOnly: true,
    });
    window.quill = this.quill;
  };
  bindQuillEvents = () => {
    this.quill?.on('text-change', this.refreshQuillMarks);
    this.quill?.on('selection-change', (range) =>
      this.handleSelectionChange(range)
    );
  };
  handleScrollTo = (offsetTop, restore) => {
    this.quill?.scrollingContainer?.scrollTo(0, offsetTop - (restore ? 0 : 0));
  };
  refreshQuillMarks = () => {
    this.setState({
      marks:
        Array.from(
          this.quill?.scrollingContainer?.querySelectorAll(
            ['[style]', `.${cx(cls.aiTaggingContainer)}`].join(', ')
          ) || []
        ).map((el) => ({
          offsetTop: el.offsetTop,
          color: el.classList.contains(cx(cls.aiTaggingContainer))
            ? 'linear-gradient(to right, var(--sky), var(--green))'
            : el.style.backgroundColor,
        })) || [],
    });
  };
  onDetailsClose = () => {
    this.setState({ taggingDetailsEditing: null });
  };
  onTaggingEdit = (tagging) => {
    this.quill?.off('selection-change', (range) =>
      this.handleSelectionChange(range)
    );
    this.setState({ taggingDetailsEditing: tagging });
  };
  applyHighlights = (taggings, yellowTaggings) => {
    tagsToHighlights(taggings).forEach((h) =>
      this.quill?.formatText(h.startPosition, h.endPosition - h.startPosition, {
        background: applyColoringStrategy(
          this.state.coloringStrategy,
          h.tags,
          taggings,
          yellowTaggings
        ),
      })
    );
    window.setTimeout(() => this.forceUpdate());
  };

  removeHighLights = (taggings) => {
    tagsToHighlights(taggings).forEach((h) =>
      this.quill?.removeFormat(
        h.startPosition,
        h.endPosition - h.startPosition,
        'silent'
      )
    );
    window.setTimeout(() => this.forceUpdate());
  };

  applyTaggingSuggestionHighlights = (
    taggingSuggestions,
    selectedTaggingIds = []
  ) => {
    if (!this.state.showTaggingSuggestions) return;

    taggingSuggestions.forEach((taggingSuggestion) => {
      const { startIndex } = this.getQuillIndicesFor(taggingSuggestion.content);
      this.quill?.formatText(
        startIndex,
        taggingSuggestion.content.length,
        'ai-tagging',
        selectedTaggingIds.includes(taggingSuggestion.id)
          ? 'aiTaggingContainerSelected'
          : 'aiTaggingContainer'
      );
    });
    window.setTimeout(() => this.forceUpdate());
  };

  removeTaggingSuggestionHighlights = (taggingSuggestions) => {
    taggingSuggestions.forEach((taggingSuggestion) => {
      const { startIndex } = this.getQuillIndicesFor(taggingSuggestion.content);
      this.quill?.formatText(
        startIndex,
        taggingSuggestion.content.length,
        'ai-tagging',
        false
      );
    });
    window.setTimeout(() => this.forceUpdate());
  };
  onTagHover = (id) =>
    this.applyHighlights(
      this.props.taggings.data.filter(({ tag }) => tag?.id == id),
      [id]
    );
  onTagHoverOut = (id) =>
    this.applyHighlights(
      this.props.taggings.data.filter(({ tag }) => tag?.id == id)
    );
  handleChangeColoringStrategy = (payload) => {
    this.setState({ coloringStrategy: payload?.value }, () => {
      this.resetDocument();
    });
  };
  onTaggingRemove = async (tagging) =>
    tagging
      ? this.props.deleteTagging(where({ id: tagging.id })).then(() => {
          this.removeHighLights([tagging]);
        })
      : this.props.showNotification({
          title: 'Removing taggings',
          message:
            'You have to first find in text the tagging you want to remove',
          level: 'warning',
          autoDismiss: 10,
        });
  handleDoneEditing = () => {
    this.props
      .updateReport(
        this.props.match.params.projectId,
        this.props.match.params.reportId,
        { project_report: { tagging_completed: true } }
      )
      .then(this.onFinishTagging);
  };
  handleUndoneEditing = () => {
    this.props
      .updateReport(
        this.props.match.params.projectId,
        this.props.match.params.reportId,
        { project_report: { tagging_completed: false } }
      )
      .then(this.onFinishTagging);
  };
  onFinishTagging = () => {
    history.length > 1
      ? history.goBack()
      : history.replace(
          `/analysis/${this.props.match.params.projectId}/reports/`
        );
    window.resetState = 'reload';
  };
  handleReloadData = () => {
    {
      this.doRequestTaggings(false);
      this.doRequestTaggingSuggestions(false);
      this.handleSelectionChange();
    }
  };
  handleRefreshReport = () => {
    this.props.getReport(
      this.props.match.params.projectId,
      this.props.match.params.reportId
    );
  };

  getQuillIndicesFor = (content) => {
    const quillContent = this.quill.getText();
    const startIndex = quillContent.indexOf(content);

    return {
      startIndex: startIndex,
      endIndex: startIndex + content.length,
    };
  };

  onViewTaggingSuggestionsToggle = () => {
    if (this.state.showTaggingSuggestions) {
      this.removeTaggingSuggestionHighlights(
        this.props.taggingSuggestions.data
      );
    } else {
      this.doRequestTaggingSuggestions();
    }

    this.setState({
      showTaggingSuggestions: !this.state.showTaggingSuggestions,
    });
  };

  onTaggingSuggestionApprove = (taggingSuggestion, updateParams = {}) => {
    const { startIndex, endIndex } = this.getQuillIndicesFor(
      updateParams?.content || taggingSuggestion.content
    );

    const payload = {
      status: AI_TAGGING_SUGGESTION_STATUS_APPROVE,
      content: taggingSuggestion.content,
      report_content_start: startIndex,
      report_content_end: endIndex,
      ...updateParams,
    };

    this.props
      .updateTaggingSuggestion(
        where({ id: taggingSuggestion.id }).payload(payload)
      )
      .then(() => {
        this.doRequestTaggings();
        this.props.showNotification({
          title: 'Tagging suggestion approved',
        });
      });
  };

  onTaggingSuggestionDecline = (taggingSuggestion) => {
    this.props
      .updateTaggingSuggestion(
        where({ id: taggingSuggestion.id }).payload({
          status: AI_TAGGING_SUGGESTION_STATUS_DECLINE,
        })
      )
      .then(() => {
        this.onTaggingSuggestionEditClose();
        this.removeTaggingSuggestionHighlights([taggingSuggestion]);
        this.props.showNotification({
          title: 'Tagging suggestion removed',
        });
      });
  };

  onTaggingSuggestionEdit = (taggingSuggestion) => {
    this.setState({
      editTaggingSuggestion: taggingSuggestion,
    });
  };

  onTaggingSuggestionEditClose = () => {
    this.setState({
      editTaggingSuggestion: null,
    });
  };

  render() {
    if (
      this.props.report?.reportUid &&
      this.props.report.status != reportStatus.PARSED
    )
      return (
        <Page
          key="not-parsed"
          noPadding
          title={this.props.report.name}
          back="0"
        >
          <Waiting onRefresh={this.handleRefreshReport} />
        </Page>
      );

    const hasSelectedTeaggings = this.state.tagsInContext?.length > 0;
    const hasSelectedTaggingSuggestions =
      this.state.taggingSuggestionsInContext?.length > 0;
    const hasSelectedHighlight = this.state.selectedText?.length > 0;
    const markKey = `${String(this.quill && Boolean(this.innerRef))}${
      this.state.marks?.length
    }`;
    const projectUniqueKey = `${(
      this.props.project.enabled_tag_categories || []
    ).join(',')}-${String(this.state.hasTaggings)}`;
    return (
      <Page noPadding title={this.props.report.name} back="0">
        {!this.state.hasTaggings && <Progress large className="absolute" />}
        <Section horizontal grow className={cx(cls.tagEditorCardWrapper)}>
          <FlexColumn
            style={{ position: 'relative', paddingRight: 16, height: '100%' }}
            size={2 / 3}
          >
            <Card className={cls.tagEditorCard} noPadding grow>
              <Container grow className={cx(cls.editorArea)}>
                <div className={cls.editorWrapper}>
                  <pre
                    className={cls.editor}
                    ref={(ref) => (this.innerRef = ref)}
                  />
                  {this.state.taggingDetailsEditing?.id && (
                    <TaggingDetailsSelector
                      open
                      isEdit={true}
                      tagging={this.state.taggingDetailsEditing}
                      position={{
                        top: this.quill?.getBounds(this.state.selectionEnd)
                          ?.bottom,
                        left: this.quill?.getBounds(this.state.selectionEnd)
                          ?.left,
                      }}
                      onSubmit={
                        this.state.taggingDetailsEditing?.id &&
                        this.handleEditTagging
                      }
                      onRequestClose={this.onDetailsClose}
                      onTaggingRemove={this.onTaggingRemove}
                    />
                  )}
                </div>
                <EditorMinimap
                  key={markKey}
                  editorScrollHeight={
                    window.quill?.scrollingContainer?.scrollHeight ?? 0
                  }
                  onScrollTo={(pos) => this.handleScrollTo(pos, false)}
                  marks={this.state.marks}
                />
              </Container>

              {hasSelectedTeaggings && (
                <section className={cls.tagsInContext}>
                  <Container horizontal style={{ gap: 3 }}>
                    <label style={{ lineHeight: '18px' }}>Tagged with:</label>
                    {this.state.tagsInContext.map((t) => (
                      <TaggingInContext
                        key={t.id}
                        tagging={t}
                        onTaggingRemove={this.onTaggingRemove}
                        onTaggingEdit={this.onTaggingEdit}
                      />
                    ))}
                  </Container>
                </section>
              )}
              {hasSelectedTaggingSuggestions && (
                <section className={cls.tagsInContext}>
                  <Container horizontal style={{ gap: 3 }}>
                    <label style={{ lineHeight: '18px' }}>
                      Recommended tag:
                    </label>
                    {this.state.taggingSuggestionsInContext.map(
                      (taggingSuggestion) => (
                        <TaggingSuggestionInContext
                          key={taggingSuggestion.id}
                          taggingSuggestion={taggingSuggestion}
                          onTaggingSuggestionDecline={
                            this.onTaggingSuggestionDecline
                          }
                          onTaggingSuggestionEdit={() =>
                            this.onTaggingSuggestionEdit(taggingSuggestion)
                          }
                        />
                      )
                    )}
                  </Container>
                </section>
              )}
              {this.state.editTaggingSuggestion && (
                <AITaggingSuggestionEditor
                  taggingSuggestion={this.state.editTaggingSuggestion}
                  onRequestClose={this.onTaggingSuggestionEditClose}
                  onRequestSaveAndAccept={this.onTaggingSuggestionApprove}
                  onRequestDecline={this.onTaggingSuggestionDecline}
                />
              )}
              {hasSelectedHighlight && (
                <section className={cls.tagsInContext}>
                  <small>
                    <label style={{ fontStyle: 'normal' }}>
                      Selected text:&nbsp;
                    </label>
                    {this.state.selectedText?.length > 100
                      ? `${String(this.state.selectedText).substring(
                          0,
                          100
                        )}...`
                      : this.state.selectedText}
                  </small>
                </section>
              )}
              <CardFooter
                key={this.props.report.reportUid}
                className={cls.tagEditorFooter}
              >
                <Container horizontal spaceBetween>
                  <FormatEditor />
                  {this.props.report.reportUid && (
                    <span>
                      <QuotesList
                        key={this.props.report.reportUid}
                        taggableType="reports"
                        type="footer"
                        taggableUid={this.props.report.reportUid}
                        selectedText={this.state.selectedText}
                        selectionStart={this.state.selectionStart}
                        selectionEnd={this.state.selectionEnd}
                        setSelection={this.setSelection}
                      />
                    </span>
                  )}
                </Container>
              </CardFooter>
            </Card>
          </FlexColumn>

          <FlexColumn
            style={{
              padding: 0,
              position: 'relative',
              height: '100%',
              minWidth: 286,
            }}
            size={1 / 3}
          >
            <TagEditorSidebar
              key={projectUniqueKey}
              project={this.props.project}
              report={this.props.report}
              taggings={this.props.taggings}
              tagsInContext={this.state.tagsInContext}
              selectionStart={this.state.selectionStart}
              selectionEnd={this.state.selectionEnd}
              selectedText={this.state.selectedText}
              coloringStrategy={this.state.coloringStrategy}
              changeColoringStrategy={this.handleChangeColoringStrategy}
              addTagging={this.addTaggings}
              removeTagging={this.onTaggingRemove}
              onTagHover={this.onTagHover}
              onTagHoverOut={this.onTagHoverOut}
              setSelection={this.setSelection}
              onViewTaggingSuggestionsToggle={
                this.onViewTaggingSuggestionsToggle
              }
              onApproveTaggingSuggestion={this.onTaggingSuggestionApprove}
              onDeclineTaggingSuggestion={this.onTaggingSuggestionDecline}
              onEditTaggingSuggestion={this.onTaggingSuggestionEdit}
              showTaggingSuggestions={this.state.showTaggingSuggestions}
              taggingSuggestions={this.props.taggingSuggestions.data}
              onDoneEditing={
                this.props.report.tagging_completed_at
                  ? undefined
                  : this.handleDoneEditing
              }
              onUndoneEditing={
                this.props.report.tagging_completed_at
                  ? this.handleUndoneEditing
                  : undefined
              }
              onReloadData={this.handleReloadData}
            />
          </FlexColumn>
        </Section>
      </Page>
    );
  }
}
